<template>
  <div class="userList">
    <searchBar :formItems="formItems" @search="search" />
    <div class="tableBox">
      <el-table :data="tableData" :cell-style="{ textAlign: 'center' }" :header-cell-style="{ textAlign: 'center' }"
        border style="width: 100%">

        <el-table-column type="index" :index="indexMethod" label="序号" width="80">
        </el-table-column>
        <el-table-column prop="openid" label="openid" width="280"></el-table-column>
        <el-table-column prop="account" label="提现账号" textAlign="left"></el-table-column>
        <el-table-column prop="money" label="提现金额" textAlign="left" width="150">
          <template slot-scope="scope">
            {{ parseFloat(scope.row.money) / 100 }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="申请提现时间" width="180">
          <template slot-scope="scope">
            {{ scope.row.createTime | formatTime('YMDHMS') }}
          </template>
        </el-table-column>
        <el-table-column prop="dealTime" label="处理时间" width="180">
          <template slot-scope="scope">
            {{ scope.row.dealTime | formatTime('YMDHMS') }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="180">
          <template slot-scope="scope">
            <span :class="scope.row.status === '1' ? 'c_green' : ''">
              {{ scope.row.status === '0' ? '未提现' : "已提现" }}
            </span>
          </template>
        </el-table-column>

        <el-table-column prop label="操作" width="200">
          <template slot-scope="scope">
            <el-button @click="dealTixian(scope.row)" type="text" size="small"
              v-if="scope.row.status === '0'">处理</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fenye">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page.page" :page-sizes="[5, 10, 20, 30, 50]" :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
  </div>
</template>

<script>
import searchBar from "@/components/SearchBar/index";
export default {
  components: { searchBar },
  data() {
    return {
      page: {
        page: 1,
        pageSize: 10,
      },
      params: { },
      total: 0,
      tableData: [],
      formItems: [
        {
          label: "",
          type: "el-select",
          fieldName: "status",
          attrs: {
            placeholder: "状态",
            value:'0',
          },
          
          data: [
            {
              label: '全部',
              value: ''
            },
            {
              label: '未提现',
              value: '0'
            },
            {
              label: '已提现',
              value: '1'
            }
          ]
        },
      ],
    };
  },
  methods: {
    indexMethod(index) {
      return index + 1 + (this.page.page - 1) * this.page.pageSize;
    },
    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.initPage();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.initPage();
    },
    search(opt) {
      //搜索
      this.params = opt;
      this.initPage();
    },
    initPage() {
      this.getAjax(
        "api/tixian/list",
        { ...this.params, ...this.page },
        "get"
      ).then((res) => {
        this.tableData = res.data.list.records;
        this.total = res.data.list.total;
      });
    },
    dealTixian(item) {
      this.$confirm("确定已返现了吗？").then(() => {
        this.getAjax("api/tixian/deal", { id: item.id }, "get").then(
          (res) => {
            this.$message.success("处理成功");
            this.initPage();
          }
        );
      });
    },
  },
  mounted() {
    this.initPage();
  },
};
</script>

<style lang="less">
.tianfulove {
  width: 100%;
  height: 100%;
  display: flex;

  .leftBox {
    width: 10%;
min-width:130px;
    height: 100%;
    background: #545c64;
  }

  .rightBox {
    flex: 1;
    padding: 20px;

    .fenye {
      text-align: right;
      padding: 15px 10px;
    }
  }
}
</style>
